.icon-adap {
  position: relative;
  top: 2px;
  cursor: pointer;
}
.logo-menu{
  width: 10vh;
}

.div-scroll-familia{
  overflow-y: scroll;
    max-height: 63vh;
}

.chip {
  height: 20px !important;
  font-size: 10px !important;
  line-height: 0px !important;
}

.material-tooltip {
	background-color: transparent !important;
}
.black-tooltip{
  background-color:#323232 !important;
  text-transform: none !important;
}
.chip-detail {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-right: 5px;
}

#cardcentrosbuscados{
  height: 60vh !important;

}

#cardcentrosbuscados2{
  height: 60vh !important;

}
.btn-famili-per{
  line-height: 31px !important;
}


.collection-est {
  margin: .5rem 0 1rem 0;
  border: none !important;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  width: 98%;
}

.collection-item-est {
  background-color: #fff;

  padding: 10px 15px;
  margin: 10px 0px;
  border-radius: 6px;
}

.card-content-est {
  padding: 0px !important;
  margin: 0px !important;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  min-height: 20vh;
  max-height: 70vh;

  
}

.card-content-gpvs {
  background-color: #fff;
  padding: 0px !important;
  margin: 0px !important;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  min-height: 20vh;
  max-height: 20vh;
}

/*
      CCS A SUBIR AL GIT
*/

.App {
  text-align: center;
}

.tabla-kpis {
  margin: 20px 0px;
}

.collapsible {
  margin: 2px !important;
}

#collapsiblerutas, .collapsible_promo {
  margin-left: 2%;
  margin-right: 2%;
  position: sticky;
}
#collapsiblerutas2 {
  overflow-y: auto;
  position: sticky;
  max-height: 45vh;
}

.containernameshop {
  font-size: 12px;
  font-weight: 600;
}

#containershop {
  margin: 0px !important;
  padding: 0px 0px !important;
}

.vertical-center {
  display: inline-block;
  vertical-align: middle;
}

[type="checkbox"]+span:not(.lever) {
  top: 3px;
  left: 8px
}

/*
-----------------------------------------
Modulo Surtido 
-----------------------------------------
*/



.td-style{
  padding: 0px !important;
  border-radius: none !important;
}

.collapsible-header{

  padding-left: 26px !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  border-bottom: 2px solid #ddd !important;
  border-radius: 6px !important;
}

.table-responsive{
  border-radius: 6px;
  padding: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.selectfamiliasurtido .select-wrapper input.select-dropdown {
  height: 3rem !important;
  font-size: 1rem !important;
}

li.active .listasurtido {
  /* background-color: #85D1BC  !important; */
  background-color: #24AF88  !important;
  /* background: conic-gradient(from -71.42deg at 0% 100%, #24AF88 -243.43deg, rgba(255, 255, 255, 0) 79.19deg, #24AF88 82.12deg, rgba(255, 255, 255, 0.849194) 86.59deg, #24AF88 90.4deg, rgba(255, 255, 255, 0.56) 97.42deg, #24AF88 103.96deg, rgba(255, 255, 255, 0.246333) 113.95deg, #24AF88 116.57deg, rgba(255, 255, 255, 0) 439.19deg), #24AF88 !important; */

  color: white;
}

#containerprodsimpar {
  padding: 0px;
}

table.highlight>tbody>tr {
  border-bottom: 2px solid #b3b3b3 !important;
}

.addsurtido td {
  color: white !important;
}

table.highlight>tbody>tr.addsurtido {
  background-color: #ff7b24e6 !important;
}

table tr.nohover {
  background-color: #ffffff !important;
}

.row {
  margin-bottom: 0px !important;
}

#bodycollapsiblesurtido {
  padding: 1px !important;
}

#bodycollapsiblesurtido .row {
  width: 100.99%;
}

.containertittlefamilia {
  background-color: #5995ca;
  color: white;
  border-bottom: 1px solid #f2f2f2;
}

.tittle_table_surtido {
  padding: 1%;
  margin: 0;
}

.trtheadsurtido {
  background-color: #86E1CA !important;
  color: rgb(39, 38, 38);
  font-size: 12px;
  height: 40px;
}

.trtheadsurtido td, .trtheadsurtido th {
  padding: 10px 5px;
}

.trtheadsurtido th {
  padding: 10px 5px;
  border-radius: none !important;
}

#collapsibleulsurtido {
  margin-top: 0;
  margin-bottom: 0;

}

#containerprodspar {
  padding-right: 0;
  border-right: 1px solid white;
}

#containerprodsimpar {
  padding-left: 0;
  border-left: 1px solid white;
}

#checkoutsurtido, #checkinsurtido, #editarsurtido {
  cursor: pointer;
}

.button-multi-sel {
  background-color: #29B6F6 !important;
  ;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
}

.button-multi-sel:hover {
  background-color: rgb(18, 156, 219) !important;
}

.labelcheckall span {
  position: relative;
  padding-left: 1px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  float: left;
  line-height: 1.5;
  margin-top: 2px;
  margin-left: 5px;
  user-select: none;
  font-size: 0.9rem;
  color: white;
}

.collapsible-header.listasurtido {
  background-color: #24AF88  !important;
  color: white
}

.num-ref-surtido {
  font-size: 25px;
  color: #FFDE01;
}

/*
-----------------------------------------
Fin Modulo Surtido
-----------------------------------------
*/

p.font-kpi {
  height: 20px;
}

iframe {
  display: block;
  /* iframes are inline by default */
  background: transparent;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 100vw;
}

.new-pos{
  position: fixed !important;
  right: 45px !important;
  bottom: 4px !important;
  padding-top: 15px !important;
  margin-bottom: 0;
  z-index: 997;
}

/*
-----------------------------------------
Modulo Login
-----------------------------------------
*/

@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended+Text&display=swap');
.bg-orange-ps {
  background-color: #FF7E33 !important;
}

.head-log {
  height: 25vh;
  border-bottom-left-radius: 6em;
  margin-left: 3px;
}

.div-imput {
  display: inline-block;
  padding: 32px 48px 32px 48px;
  border: 1px;
  margin-top: 20px;
}

.btn-log {
  border-radius: 25px !important;
  background-color: #FF7E33 !important;
  color: white;
  float: none !important;
  margin: 0px auto !important;
}

.product-name {
  font-family: 'Libre Barcode 39 Extended Text', cursive;
  font-size: 6rem;
  text-align: center;
  margin: 0px;
  padding-top: 3%;
  color: whitesmoke;
}

#imgLogo {
  height: 120px !important;
  width: auto;
}

.filtros-surtido {
  margin-top: 100px !important;
}

/*
-----------------------------------------
Fin Modulo Login
-----------------------------------------
*/



.card {
  padding: 0px !important;
  height: 42vh;
  border-radius: 6px;

}

.card .card-content {
  padding: 24px;
  border-radius: 0 0 2px 2px;
  overflow-y: auto;
  max-height: 79%;
}

.card-content-ruta {
  padding: 0px !important;
  margin: 0px !important;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  max-height: 79% !important;
}

.card-content-gpvs {
  padding: 0px !important;
  margin: 0px !important;
  overflow-x: auto !important;
  overflow-y: scroll !important;
  min-height: 20vh;
  max-height: 20vh;
}

.border-table {
  /* border-collapse: separate !important;
  border-spacing: 2px !important; */
  border-right: solid 2px white !important;
  border-left: solid 2px white !important;
}

.ReactModal__Overlay {
  z-index: 1 !important;
}

.center {
  text-align: -webkit-center;
}

.modal-full {
  z-index: 9999 !important;
}

.box-kpi {
  padding-right: 5px;
  margin-bottom: 5px;
}

.variable-cont {
  width: 170vh;
  margin: auto !important;
}

.tit-kpi {
  font-weight: bold !important;
  font-size: 16px;
}

.border-rad {
  border-radius: 10px !important;
}

.mask-kpi>div>input {
  border-bottom: 1px solid #ffffff !important;
}


.btn-floating {
  height: 30px !important;;
  width: 30px !important;
  padding: 0 !important;
}

.btn-modal {
  margin: 10px 5px;
  bottom: 15px;
}

.modal {
  overflow-y: unset !important;
  max-height: none !important;
  border-radius: 6px !important;
}

.grid-kpi {
  /* max-width: 137vh !important; */
  margin: 0px auto !important;
}

.leyenda {
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 20px;
}

.mask-kpi {
  padding: 5px;
  border-radius: 6px;

}

.btn-modal {
  margin: 10px 5px;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

input {
  background-color: transparent !important;
}

.box-kpi  {
  color: white;
}

.App-logo {
  height: 40vmin;
  margin-bottom: 50px;
  pointer-events: none;
}

/*MARGIN*/

.margin-with{

  width: 100% !important;
  margin-left: -6px !important;

}

.margin-btn {
  margin-right: 2px;
}

.margin0 {
  margin: 0px !important;
}

.margin5-5 {
  margin: 5px 0px 5px 0px !important;
}

.margin-bot3p {
  margin-bottom: 3%;
}

.margin-bot30 {
  margin-bottom: 30px !important;
}

.margin-top10p {
  margin-top: 10%;
}


.margin-5-5 {
  margin: 0px 5px 0px 5px !important;
}

.margin-bot5 {
  margin-bottom: 5px;
}

.margin-bot0 {
  margin-bottom: 0px !important; 
}
.margin-bot-rem {
  margin-bottom: 1.86rem !important; 
}

.margin-top-rem-02 {
  margin-bottom: 0.2rem !important; 
}

.margin-top-2p{
  margin-top: 2% !important; 
}

.margin-top-1p{
  margin-top: 1% !important; 
}

/*PADDINGS*/


.padding0 {
  padding: 0px !important;
}

.padding5-5 {
  padding: 5px 0px 5px 0px !important;
}

.pad-top-porc{
  padding-top: 1% !important;
}

.pad-top-porc-5{
  padding-top: 5% !important;
}

.pad-rig-15{
  padding-right: 15px ;

}

.pad-bot-40{
  padding-bottom: 40px !important;

}

.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.link-details {
  padding: 0px;
  margin-right: -20px;
}
.width-5{
  width: 5% !important;
}

.width-7{
  width: 7% !important;
}

.size-1 {
  /* UTILIZAR SOLO POR BTN REMOVE EDIT DELETE*/
  font-size: 20px !important; 
  line-height: 2px !important;
}

.size-2 {
  font-size: 10px !important;
}

.size-3 {
  font-size: 12px !important;
}

.size-4 {
  font-size: 15px !important;
}

.size-5 {
  font-size: 18px !important;
}

.size-6 {
  font-size: 20px !important;
}

.size-7 {
  font-size: 22px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fill-complet-kpis {
  background-color: yellowgreen;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  padding: 5px 25px;
}

.widthKpiTotal {
  width: 130px !important;
}

.App-link {
  color: #61dafb;
}

.color:hover {
  background-color: #ff7e33 !important;
}

.titulo-pagina {
  padding-top: 20px;
  padding-bottom: 20px !important;
  width: 65%!important;
  text-align: center;
  border-radius: 15px !important;
  margin-bottom: 6% !important;
  margin-top: 3% !important;
  margin: auto;
}

.color-presencia-icon {
  color: #FEC107;
}

.icon-point {
  padding-top: 5px;
}

.icon-text {
  vertical-align: super;
}

.center-col {
  margin: 0px auto;
  float: none !important;
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.color-presencia {
  background-color: #FEC107;
}

.color-activacion-icon {
  color: #4CB0B0;
}

.color-activacion {
  background-color: #4CB0B0;
}

.color-visibilidad-icon {
  color: #EA1E63;
}

.color-visibilidad {
  background-color: #EA1E63;
}

.color-yellow {
  background-color: #FFC950 !important;
}

.color-orange {
  background-color: #FF7E33 !important;
}

.color-red {
  background-color: #FE7575 !important;
}

.color-blue {
  background-color: #75D2FF !important;
}

.color-red-mod {
  background-color: #FF4C4C !important;
}

.color-blue-mod {
  background-color: #039BE5 !important;
}

.color-purple {
  background-color: #D264E5 !important;
}

.color-aquamarine {
  background-color: #86E1CA !important;
}

.color-green {
  background-color: #24AF88 !important;
}

.color-dark-blue {
  background-color: #2f4858 !important;
}

.color-black {
  background-color: #3C3939 !important;
}

.center {
  text-align: center;
}

.item-center {
  display: block;
  margin: 0px auto;
}

nav ul a:hover {
  background-color: transparent !important;
}

.sidenav li>a {
  margin: 10px;
  /* border-radius: 10px; */
  border-bottom-right-radius: 20px;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  line-height: 48px;
  padding: 0 32px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background img {
  border-style: none;
  margin: 70px auto;
  display: block;
}

@media only screen and (min-width: 993px) {
  nav a.sidenav-trigger {
    display: block !important;
  }
}

@media only screen and (max-width: 1280px) {
  .font-kpi {
    font-size: 10px;
  }
  .variable-cont {
    width: 99%;
    margin: auto !important;
  }
}